import React, { RefObject } from 'react';

interface SieveProps {
    repeat?: number;
}

interface SieveState {
    content: string;
    repeat?: number;
}

class Sieve extends React.Component<SieveProps, SieveState> {
    public state: SieveState = {
        content: '',
    };

    readonly host: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    readonly container: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    updateContent = () => {
        this.setState({
            content: ''
        }, () => {
            const spaces = ' &zwj;'.repeat(this.props.repeat ?? 40);

            const fillContentWithRows = () => {
                this.setState({
                    content: this.state.content + '<br>' + this.state.content
                }, () => {
                    if (this.container.current && this.host.current && this.container.current.scrollHeight >= this.host.current.scrollHeight) {
                        return;
                    }

                    fillContentWithSpaces();
                });
            }

            const fillContentWithSpaces = () => {
                this.setState({
                    content: this.state.content + spaces
                }, () => {
                    if (this.container.current && this.host.current && this.container.current.scrollWidth >= this.host.current.scrollWidth) {
                        fillContentWithRows();

                        return;
                    }

                    fillContentWithSpaces();
                });
            }

            fillContentWithSpaces();
        });
    };

    render() {
        return (
            <div className="Sieve" ref={ this.host }>
                <div className="container" ref={ this.container } dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
            </div>
        );
    }

    componentDidMount() {
        window.addEventListener('load', this.updateContent);
        window.addEventListener('resize', this.updateContent);

        this.updateContent();
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.updateContent);
        window.removeEventListener('resize', this.updateContent);
    }
}

export default Sieve;
