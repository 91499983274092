import React, {ComponentProps} from 'react';
import Thickener from "../components/Thickener";
import Marquee from "../components/Marquee";

class ChallengingTheStatusQuo extends React.Component<ComponentProps<typeof ChallengingTheStatusQuo>, unknown> {
    render() {
        return (
            <section id="ChallengingTheStatusQuo">
                <aside>
                    <Thickener
                        as="h1"
                        content="Challenging<br />the status quo"
                    />
                </aside>

                <div className="articles">
                    <article className="smaller">
                        Senga empowers online social interactions and communication to happen in a way that is:<br />
                        <br />
                        <p>
                            Censorship resistant<br />
                            → peer-to-peer, <Thickener content="no central servers" delay={1000} />, no spof<br />
                            → no one would be able to <Thickener content="shut the app down" delay={2000} />
                        </p>
                        <p>
                            Reliable<br />
                            → <Thickener content="no middlemen" delay={3000} /> are involved in the handling of digital inputs and outputs<br />
                            → no one would be able to <Thickener content="access or alter the data" delay={4000} />
                        </p>
                        <p>
                            Persistent<br />
                            → immune to external factors, <Thickener content="off the grid enabled" delay={5000} /><br />
                            → <Thickener content="distributed system" delay={6000} />, being kept up and running by everybody together
                        </p>
                        <p>
                            Privacy preserving<br />
                            → doesn’t require any of <Thickener content="personal data, metadata" delay={7000} /> privacy<br />
                            → complete <Thickener content="security" delay={8000} /> and privacy of all data flows, end-to-end encryption
                        </p>
                    </article>
                </div>
                
                <Marquee
                    content="Are you interested in, and concerned about, the issues of data privacy? Do you feel you can trust today’s social media platforms? Would you say that a statement like “The use of today’s social media is free of charge” is entirely true? When using social media platforms, do you ever feel like you’re being treated as a commodity? Are you aware that uploading content to today’s widespread platforms means not being the actual owner and operator of the uploaded content? Do you think it’s a problem? Do you agree that today’s social media need innovation? Do you wish social media were more authentic? Would you say that a common goal, one of reinstituting genuine privacy and ownership, could bring you closer to other users and creators?"
                    duration="60s"
                />
            </section>
        );
    }
}

export default ChallengingTheStatusQuo;
