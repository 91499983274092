import React from 'react';
import Sieve from './Sieve';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconProps {
    fa: any;
}

class Icon extends React.Component<IconProps> {
    render() {
        return (
            <div className="Icon">
                <Sieve repeat={1} />
                <FontAwesomeIcon icon={this.props.fa} />
            </div>
        );
    }
}

export default Icon;
