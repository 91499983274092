import React, { ComponentProps } from 'react';

class Footer extends React.Component<ComponentProps<typeof Footer>, unknown> {
    private year: number = 2023;

    render() {
        this.year = (new Date()).getFullYear();

        return (
            <footer>
                <aside>
                    Copyright by senga { this.year }
                </aside>

                <aside>
                    made with contempt for major social media ☹ by <a href="https://blurbstudio.com" target="_blank" rel="noopener noreferrer">blürbstudio</a> ☺
                </aside>
            </footer>
        );
    }
}

export default Footer;
