import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
    content: string | TrustedHTML;
    route?: string;
    action?: MouseEventHandler;
    href?: string;
    target?: '_self' | '_blank' | '_parent' | '_top';
    arrow: {
        direction: 'up' | 'right' | 'down' | 'left';
        align: 'left' | 'right'
    },
    speed: number;
}

interface ButtonState {
    frame: number;
}

class Button extends React.Component<ButtonProps, ButtonState> {
    public static defaultProps = {
        content: '',
        target: '_self',
        arrow: {
            direction: 'right',
            align: 'right'
        },
        speed: 500
    };

    public state: ButtonState = {
        frame: 0
    };

    readonly arrow: string = '→';
    readonly animation: boolean = false;
    readonly anchor: React.RefObject<HTMLAnchorElement> = React.createRef<HTMLAnchorElement>();

    private animationInterval?: NodeJS.Timer;

    constructor(props: ButtonProps) {
        super(props);

        if (!props.arrow) {
            return;
        }

        switch (props.arrow.direction) {
            case 'up':
                this.arrow = '↑';
                break;
            case 'down':
                this.arrow = '↓';
                break;
            case 'left':
                this.arrow = '←';
                break;
        }

        if (props.arrow.align !== 'right') {
            return;
        }

        this.animation = true;
    }

    getContent(progress: number = 0, step: number = 5): string {
        const dummies = 48;

        let prefix: string = '';
        let suffix: string = '';

        if (this.props.arrow && this.props.arrow.align && this.props.arrow.align === 'left') {
            prefix = ' &zwj; ' + this.arrow + ' ';
            suffix = ' &zwj;'.repeat(dummies);
        } else {
            suffix = ' &zwj;'.repeat(1 + step * progress) + '</span>' + this.arrow + ' &zwj;'.repeat(Math.max(dummies - (step * progress), 0));
        }

        return '<span class="label">' + prefix + (this.props.content ?? '') + suffix;
    }

    render() {
        const content = <>
            <div dangerouslySetInnerHTML={{ __html: this.getContent(this.state.frame) }} style={{ opacity: 1 }}></div>
            { this.animation ? <div dangerouslySetInnerHTML={{ __html: this.getContent(this.state.frame + 1) }} style={{ opacity: 0 }}></div> : ''}
        </>

        if (this.props.route) {
            return (
                <Link className="Button" ref={ this.anchor } to={ this.props.route }>
                    { content }
                </Link>
            );
        }

        if (this.props.action) {
            return <a className="Button" ref={ this.anchor } href={ this.props.href ?? '#' } onClick={ this.props.action }>
                { content }
            </a>
        }

        return (
            <a className="Button" ref={ this.anchor } href={ this.props.href ?? '#' } target={ this.props.target ?? '_self' }>
                { content }
            </a>
        );
    }

    componentDidMount() {
        this.animationInterval = setInterval(() => {
            const labels = this.anchor.current?.querySelectorAll('.label');

            const length = Array.prototype.slice.call(labels).reduce((prev: Element, next: Element) => {
                return Math.max(prev.scrollWidth, next.scrollWidth);
            });

            this.setState({
                frame: length < ((this.anchor.current?.children!)[0] as HTMLElement).offsetWidth ? (this.state.frame + 1) : 0
            });
        }, this.props.speed);
    }

    componentWillUnmount() {
        clearInterval(this.animationInterval);
    }
}

export default Button;
