import React, { ComponentProps } from 'react';
import Sieve from "../components/Sieve";

class Whitepaper extends React.Component<ComponentProps<typeof Whitepaper>, unknown> {
    render() {
        return (
            <section id="Whitepaper">
                <figure>
                    <Sieve />
                </figure>

                <div className="spacer"></div>
                <div className="bottom">
                    <aside></aside>
                    <aside></aside>
                </div>
            </section>
        );
    }
}

export default Whitepaper;
