import React, { ComponentProps } from 'react';
import Thickener from "../components/Thickener";

class PowerToAndFromThePeople extends React.Component<ComponentProps<typeof PowerToAndFromThePeople>, unknown> {
    render() {
        return (
            <section id="PowerToAndFromThePeople">
                <aside>
                    <Thickener
                        as="h1"
                        content="Power to<br />and from<br />the people"
                    />
                </aside>

                <article>
                    <p>Following a purely distributed model, Senga’s infrastructure is created by its users and their individual devices. By every single one of them separately, and by all of them combined.</p>
                    <p>Think of it as DIY of the time, as significant as it gets. Members develop the network themselves, promoting the crucial values of unconstrained independence.</p>
                </article>
            </section>
        );
    }
}

export default PowerToAndFromThePeople;
