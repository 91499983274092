import React, { ComponentProps } from 'react';
import Marquee from "../components/Marquee";
import Thickener from "../components/Thickener";

class Interactions extends React.Component<ComponentProps<typeof Interactions>, unknown> {

    render() {
        return (
            <section id="Interactions">
                <p>
                    Interactions: unmediated and unbiased
                    - Consent: explicit only
                    - Independence: 100%
                    - Censorship resistance, Reliability, Persistency, Privacy preserving
                    - Security: 100%
                    - <Thickener as="strong" content="Building the future of online social life." />
                    <br /><br />
                    <Thickener as="strong" content="The Network / The Brand / The Movement" delay={2000} />
                </p>

                <Marquee
                    content="Are you interested in, and concerned about, the issues of data privacy? Do you feel you can trust today’s social media platforms? Would you say that a statement like “The use of today’s social media is free of charge” is entirely true? When using social media platforms, do you ever feel like you’re being treated as a commodity? Are you aware that uploading content to today’s widespread platforms means not being the actual owner and operator of the uploaded content? Do you think it’s a problem? Do you agree that today’s social media need innovation? Do you wish social media were more authentic? Would you say that a common goal, one of reinstituting genuine privacy and ownership, could bring you closer to other users and creators?"
                    duration="60s"
                />
            </section>
        );
    }
}

export default Interactions;
