import React, {useEffect, useRef, useState} from 'react';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import {BufferGeometry} from "three/src/Three";
import {Mesh} from "three/src/objects/Mesh";
import {useFrame} from "@react-three/fiber";
import {RootState} from "@react-three/fiber/dist/declarations/src/core/store";
import {Euler} from "three";

interface ModelProps {
    src: string;
    initPosition?: number[];
    initRotation?: number[];
    rotationX?: (delta: number, current: Euler) => number;
    rotationY?: (delta: number, current: Euler) => number;
    rotationZ?: (delta: number, current: Euler) => number;
    movement?: boolean;
}

function Model(props: ModelProps) {
    const [geometry, setGeometry] = useState<BufferGeometry>();
    const ref = useRef<Mesh>(new Mesh());

    useFrame((state: RootState, delta: number) => {
        if (!ref.current || (typeof props.movement !== 'undefined' && !props.movement)) {
            return;
        }

        if (props.rotationX) {
            ref.current.rotation.x = props.rotationX(delta, ref.current.rotation);
        }

        if (props.rotationY) {
            ref.current.rotation.y = props.rotationY(delta, ref.current.rotation);
        }

        if (props.rotationZ) {
            ref.current.rotation.z = props.rotationZ(delta, ref.current.rotation);
        }
    });

    useEffect(() => {
        const loader = new STLLoader();

        loader.load(
            props.src,
            (geo: BufferGeometry) => {
                setGeometry(geo);

                if (props.initPosition) {
                    ref.current.position.x = props.initPosition[0];
                    ref.current.position.y = props.initPosition[1];
                    ref.current.position.z = props.initPosition[2];
                }

                if (props.initRotation) {
                    ref.current.rotation.x = props.initRotation[0];
                    ref.current.rotation.y = props.initRotation[1];
                    ref.current.rotation.z = props.initRotation[2];
                }
            },
            (e: ProgressEvent) => {},
            (e: ErrorEvent) => {
                console.error(e);
            }
        );
    }, [props.src, props.initPosition, props.initRotation]);

    return (
        <mesh ref={ ref } geometry={ geometry }>
            <meshStandardMaterial color="white" />
        </mesh>
    )
}

export default Model;
