import React, {ComponentProps} from 'react';
import Thickener from "../components/Thickener";
import Button from "../components/Button";

interface ParadigmsShiftedState {
    frame: number;
}

class ParadigmsShifted extends React.Component<ComponentProps<typeof ParadigmsShifted>, ParadigmsShiftedState> {
    public state: ParadigmsShiftedState = {
        frame: 0
    };

    private animationInterval?: NodeJS.Timer;

    render() {
        return (
            <section id="ParadigmsShifted">
                <article className="smaller">
                    <Thickener
                        as="h1"
                        content="Paradigms<br />shifted"
                    />

                    <div>
                        <p>
                            <Thickener content="Born in the network" delay={1000} /><br />
                            → no personal data needed<br />
                            → via QR code or BLE connection<br />
                            → no bots<br />
                            → no spam
                        </p>

                        <p>
                            <Thickener content="Obtain Decentralized Identity" delay={2000} /><br />
                            → digital footprint management<br />
                            → security<br />
                            → privacy<br />
                            → control<br />
                            → pass to Senga features
                        </p>
                    </div>

                    <Button
                        route="/features"
                        content="pass to Senga features"
                        arrow={{
                            direction: "right",
                            align: "right"
                        }}
                    ></Button>
                </article>

                <figure className={'strawberry strawberry-' + (this.state.frame + 1)}></figure>

                <article className="smaller">
                    <div>
                        <p>
                            <Thickener content="Make a use of the P2P network on which your DID lives" delay={3500} /><br />
                            → IPFS<br />
                            → Cryptography<br />
                            → Blockchain<br />
                            → Bluetooth Low Energy<br />
                            → available on Android / IOS
                        </p>
                        <p>
                            <Thickener content="Create and maintain Senga" delay={5500} /><br />
                            → run Senga Nodes<br />
                            → safeguard the code<br />
                            → invite new Members<br />
                            → build micro or macro networks
                        </p>
                    </div>
                </article>
            </section>
        );
    }

    componentDidMount() {
        this.animationInterval = setInterval(() => {
            this.setState({
                frame: this.state.frame < 2 ? (this.state.frame + 1) : 0
            });
        }, 150);
    }

    componentWillUnmount() {
        clearInterval(this.animationInterval);
    }
}

export default ParadigmsShifted;
