import React, { ComponentProps } from 'react';

class Product extends React.Component<ComponentProps<typeof Product>, unknown> {
    render() {
        return (
            <section id="Product"></section>
        );
    }
}

export default Product;
