import React, { ComponentProps } from 'react';
import Sieve from '../components/Sieve';
import Button from '../components/Button';
import Thickener from "../components/Thickener";

class GetTheApp extends React.Component<ComponentProps<typeof GetTheApp>, unknown> {
    render() {
        return (
            <section id="GetTheApp">
                <figure>
                    <Sieve />
                </figure>

                <article>
                    <div>
                        <Thickener as="strong" content="Designed to:" />
                        <div>- Perform where other solutions fail</div>
                        <div>- Withstand the the obstacles that may come our way</div>
                    </div>
                    <p>
                        Senga Dappers <Thickener as="strong" content="mission" /> is to build and maintain the independent, reliable and persistent source of information and communication resilient to external factors.
                    </p>
                </article>
                <aside>
                    <p>Join the movement</p>
                    <Button
                        route="/get-updates"
                        content="Get updates"
                        arrow={{
                            direction: "right",
                            align: "right"
                        }}
                    ></Button>
                </aside>
            </section>
        );
    }
}

export default GetTheApp;
