import React, { ComponentProps } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import AOS from 'aos';

import Intro from './sections/Intro';
import GetTheApp from './sections/GetTheApp';
import Product from './sections/Product';
import ParadigmsShifted from "./sections/ParadigmsShifted";
import ChallengingTheStatusQuo from "./sections/ChallengingTheStatusQuo";
import Power from "./sections/Power";
import PowerToAndFromThePeople from "./sections/PowerToAndFromThePeople";
import Interactions from "./sections/Interactions";
import Whitepaper from "./sections/Whitepaper";
import Footer from './components/Footer';
import Page from './components/Page';
import GetUpdates from './sections/GetUpdates';
import Features from './sections/Features';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
}

class App extends React.Component<ComponentProps<typeof App>, unknown> {
    render() {
        return (
            <main>
                <Intro />
                <GetTheApp />
                <Product />
                <ChallengingTheStatusQuo />
                <ParadigmsShifted />
                <Power />
                <PowerToAndFromThePeople />
                <Interactions />
                <Whitepaper />

                <Footer />

                <Routes>
                    <Route path="/get-updates" element={
                        <Page content={ <GetUpdates /> } />
                    } />

                    <Route path="/features" element={
                        <Page content={ <Features /> } />
                    } />
                </Routes>
            </main>
        );
    }

    componentDidMount() {
        AOS.init();
    }
}

export default App;
