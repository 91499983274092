import {useFrame, useThree} from "@react-three/fiber";
import {useEffect, useMemo} from "react";
import {SengaAsciiEffect} from "../helpers/SengaAsciiEffect";

function AsciiRenderer() {
    const { size, gl, scene, camera } = useThree();

    const effect = useMemo(() => {
        const effect = new SengaAsciiEffect(gl);

        effect.domElement.style.position = 'absolute';
        effect.domElement.style.top = '0';
        effect.domElement.style.left = '0';
        effect.domElement.style.pointerEvents = 'none';

        return effect;
    }, [gl])

    useEffect(() => {
        gl.domElement.style.opacity = '0';
        gl.domElement.parentNode?.appendChild(effect.domElement);

        return () => {
            gl.domElement.style.opacity = '1';
            gl.domElement.parentNode?.removeChild(effect.domElement);
        }
    }, [gl, effect])

    useEffect(() => {
        effect.setSize(size.width, size.height);
    }, [effect, size]);

    useFrame((state: unknown) => {
        effect.render(scene, camera);
    }, 1);

    return <></>
}

export default AsciiRenderer;
