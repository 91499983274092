import React, {ComponentProps, RefObject, Suspense} from 'react';
import {Canvas} from "@react-three/fiber";
import Model from "../components/Model";
import AsciiRenderer from "../components/AsciiRenderer";
import {Euler} from "three";

interface PowerState {
    initialRotationX?: number;
    initialRotationY?: number;
    initialRotationZ?: number;
    gravityX: number;
    gravityY: number;
}

class Power extends React.Component<ComponentProps<typeof Power>, PowerState> {
    readonly MODEL_INIT_ROTATION: number[] = [Math.PI / 9, Math.PI / 5, Math.PI / 6];
    readonly GRAVITY_SCALING_FACTOR: number = 0.2;

    readonly figure: RefObject<HTMLElement> = React.createRef<HTMLElement>();

    private mouseX: number = 0;
    private mouseY: number = 0;
    private clientY: number = 0;

    private gravityX: number = 0;
    private gravityY: number = 0;

    getCameraFOV(): number {
        if (window.outerWidth < window.outerHeight) {
            return 23;
        }

        return 30;
    }

    rotationX(delta: number, current: Euler): number {
        return this.MODEL_INIT_ROTATION[0] + (this.gravityX * this.GRAVITY_SCALING_FACTOR);
    }

    rotationY(delta: number, current: Euler): number {
        return this.MODEL_INIT_ROTATION[1] + (this.gravityY * this.GRAVITY_SCALING_FACTOR);
    }

    rotationZ(delta: number, current: Euler): number {
        return current.z;
    }

    updateGravity = (horizontal: boolean = true): void => {
        const figure = this.figure.current;

        if (!figure) {
            return;
        }

        if (this.mouseY < figure?.offsetTop) {
            this.gravityY = -1;
        } else if (this.mouseY > figure.offsetTop + figure.offsetHeight) {
            this.gravityY = 1;
        } else {
            this.gravityY = -1 + 2 * ((this.mouseY - figure?.offsetTop) / figure.offsetHeight);
        }

        if (!horizontal) {
            return;
        }

        this.gravityX = -1 + 2 * (this.mouseX / document.body.clientWidth);
    }

    onMouseMove = (e: MouseEvent): void => {
        if (window.outerWidth < window.outerHeight) {
            return;
        }

        this.mouseX = e.clientX;
        this.mouseY = e.clientY + document.documentElement.scrollTop;
        this.clientY = e.clientY;

        this.updateGravity();
    }

    onScroll = (e: Event): void => {
        if (window.outerWidth < window.outerHeight) {
            this.gravityX = 0;
            this.mouseY = document.documentElement.scrollTop + (window.outerHeight / 2);
        } else {
            if (!this.mouseY) {
                return;
            }

            this.mouseY = this.clientY + document.documentElement.scrollTop;
        }

        this.updateGravity(false);
    }

    render() {
        return (
            <section id="Power" ref={ this.figure }>
                <figure>
                    <div className="canvas">
                        <Canvas camera={{ fov: this.getCameraFOV(), position: [20, -110, 25] }}>
                            <Suspense>
                                <pointLight position={ [120, 320, 1000] } />
                                <Model
                                    src="models/fist.stl"
                                    initPosition={ [-140, 80, -185] }
                                    initRotation={ this.MODEL_INIT_ROTATION }
                                    rotationX={ (delta: number, current: Euler) => this.rotationX(delta, current) }
                                    rotationY={ (delta: number, current: Euler) => this.rotationY(delta, current) }
                                    rotationZ={ (delta: number, current: Euler) => this.rotationZ(delta, current) }
                                    movement={ true }
                                />
                            </Suspense>
                            <AsciiRenderer />
                        </Canvas>
                    </div>
                </figure>
            </section>
        );
    }

    componentDidMount(): void {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('scroll', this.onScroll);
    }
}

export default Power;
