import React, { ReactNode } from 'react';

interface PageProps {
	content: ReactNode;
}

class Page extends React.Component<PageProps, unknown> {
	render() {
		return (
			<div className="Page">
				{ this.props.content }
			</div>
		);
	}

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	}

	componentWillUnmount() {
		document.body.style.overflow = 'visible';
	}
}

export default Page;
