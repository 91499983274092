import React, {ComponentProps, Suspense} from 'react';
import Marquee from '../components/Marquee';
import {Canvas} from "@react-three/fiber";
import Model from "../components/Model";
import AsciiRenderer from "../components/AsciiRenderer";
import {Euler} from "three";
import Thickener from "../components/Thickener";

class Intro extends React.Component<ComponentProps<typeof Intro>, unknown> {
    private delay?: {
        body: number;
        header: number;
    };

    render() {
        this.delay = {
            body: 0,
            header: 3000
        }

        if (window.outerWidth < window.outerHeight) {
            this.delay = {
                body: 2000,
                header: 0
            }
        }

        return (
            <section id="Intro">
                <article>
                    <h1>
                        <>Senga: <Thickener as="strong" content="Independent & Tamper proof communication" /> tool</>
                    </h1>
                    <aside>
                        <div className="canvas">
                            <Canvas camera={{ fov: 6.2, position: [400, 50, 0]}}>
                                <Suspense>
                                    <pointLight position={ [100, 1000, 1000] } />
                                    <pointLight position={ [-500, 100, -400] } />
                                    <Model
                                        src="models/strawberry.stl"
                                        initRotation={ [Math.PI / 2, -.9 * Math.PI, Math.PI / 2] }
                                        rotationX={ (delta: number, current: Euler) => current.x + 0.002 * Math.sin(current.z + (Math.PI / 4)) }
                                        rotationZ={ (delta: number, current: Euler) => current.z + 0.48 * delta }
                                        movement={ true }
                                    />
                                </Suspense>
                                <AsciiRenderer />
                            </Canvas>
                        </div>
                    </aside>
                    <p>
                        The robust solution works as a decentralized mobile application based on a peer-to-peer network created and maintained by <Thickener as="strong" content="Senga Dappers" delay={ this.delay.header } /> themselves.
                    </p>
                </article>

                <Marquee
                    content="Are you interested in, and concerned about, the issues of data privacy? Do you feel you can trust today’s social media platforms? Would you say that a statement like “The use of today’s social media is free of charge” is entirely true? When using social media platforms, do you ever feel like you’re being treated as a commodity? Are you aware that uploading content to today’s widespread platforms means not being the actual owner and operator of the uploaded content? Do you think it’s a problem? Do you agree that today’s social media need innovation? Do you wish social media were more authentic? Would you say that a common goal, one of reinstituting genuine privacy and ownership, could bring you closer to other users and creators?"
                    duration="60s"
                />
            </section>
        );
    }
}

export default Intro;
