import React from 'react';

interface MarqueeProps {
    content: string;
    duration: string;
}

class Marquee extends React.Component<MarqueeProps, unknown> {
    render() {
        return (
            <div className="Marquee">
                <p style={{ animationDuration: this.props.duration, WebkitAnimationDuration: this.props.duration }} dangerouslySetInnerHTML={{ __html: this.props.content }}></p>
                <p style={{ animationDuration: this.props.duration, WebkitAnimationDuration: this.props.duration }} dangerouslySetInnerHTML={{ __html: this.props.content }}></p>
            </div>
        );
    }
}

export default Marquee;
